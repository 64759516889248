// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

#admin-page {
  padding-top: 0px;

}

.profile-photo {

   height:70px;
   width: 70px;
   padding: 5px;

 }

.profile-photo-edit {

  height:auto;
  width: 150px;
  padding: 5px;

}

.no-border {

  border: 0 ;
  box-shadow: none ;

}



.comments-photo{

  width: 50px;
  height: 50px;

}

.comment-reply{

  display: none;

}
.navbar-brand{

  padding: 5px 15px;

}

.field-icon {
  float: right;
  /* margin-left: 22px; */
  margin-top: -24px;
  margin-right: 6px;
  /* position: relative; */
  z-index: 2;
}